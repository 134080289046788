import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7e04ca21")
const _hoisted_1 = {
  key: 0,
  class: "page--limited"
}
const _hoisted_2 = { class: "pickup_and_dropoff no_break_inside" }
const _hoisted_3 = {
  key: 4,
  class: "p-px-5 p-text-center"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_OrderToolbar = _resolveComponent("OrderToolbar")!
  const _component_OrderTimeline = _resolveComponent("OrderTimeline")!
  const _component_OrderStatusDetails = _resolveComponent("OrderStatusDetails")!
  const _component_OrderData = _resolveComponent("OrderData")!
  const _component_CustomerSummary = _resolveComponent("CustomerSummary")!
  const _component_PickerSummary = _resolveComponent("PickerSummary")!
  const _component_OrderNotes = _resolveComponent("OrderNotes")!
  const _component_PriceAndCostSummary = _resolveComponent("PriceAndCostSummary")!
  const _component_OrderPayment = _resolveComponent("OrderPayment")!
  const _component_OrderAssignmentDialog = _resolveComponent("OrderAssignmentDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.order)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PageHeader, {
          backRoute: _ctx.backRoute,
          showDelete: _ctx.isTMT,
          showSave: _ctx.showSaveButton,
          title: `Ordine #${_ctx.order.id}`,
          onOnDelete: _ctx.onDelete,
          onOnSave: _ctx.update
        }, {
          leftActions: _withCtx(() => [
            ((_ctx.isTMT && !_ctx.isAnticipated) || _ctx.isCustomer)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  label: _ctx.$t('clone'),
                  loading: _ctx.isCloneInProgress,
                  class: "p-mr-1 p-button-secondary",
                  icon: "pi pi-clone",
                  onClick: _ctx.clone
                }, null, 8, ["label", "loading", "onClick"]))
              : _createCommentVNode("", true),
            (_ctx.isSupplier && (_ctx.order.status < 200) )
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 1,
                  label: _ctx.$t('delivered'),
                  loading: _ctx.requestPending,
                  class: "p-mr-1",
                  icon: "pi pi-send",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.droppedOff(_ctx.order)))
                }, null, 8, ["label", "loading"]))
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_Button, {
              class: "p-mr-1 p-button-info",
              icon: "pi pi-print",
              onClick: _ctx.print
            }, null, 8, ["onClick"]), [
              [
                _directive_tooltip,
                _ctx.$t('print'),
                void 0,
                { bottom: true }
              ]
            ]),
            (_ctx.isCustomer && _ctx.order.status === 0)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 2,
                  label: _ctx.$t('cancel'),
                  class: "p-mr-1 p-button-danger",
                  icon: "pi pi-times",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancel(_ctx.order)))
                }, null, 8, ["label"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["backRoute", "showDelete", "showSave", "title", "onOnDelete", "onOnSave"]),
        (_ctx.isTMT)
          ? (_openBlock(), _createBlock(_component_OrderToolbar, {
              key: 0,
              disabled: _ctx.isRefunded,
              order: _ctx.order,
              onAccept: _cache[2] || (_cache[2] = ($event: any) => (_ctx.accept(_ctx.order))),
              onReject: _cache[3] || (_cache[3] = ($event: any) => (_ctx.reject(_ctx.order))),
              onAssign: _cache[4] || (_cache[4] = ($event: any) => (_ctx.assign())),
              onWaitingPickup: _cache[5] || (_cache[5] = ($event: any) => (_ctx.waitingPickup(_ctx.order))),
              onPickedUp: _cache[6] || (_cache[6] = ($event: any) => (_ctx.pickedUp(_ctx.order))),
              onWaitingDropoff: _cache[7] || (_cache[7] = ($event: any) => (_ctx.waitingDropoff(_ctx.order))),
              onDroppedOff: _cache[8] || (_cache[8] = ($event: any) => (_ctx.droppedOff(_ctx.order))),
              onCancel: _cache[9] || (_cache[9] = ($event: any) => (_ctx.cancel(_ctx.order)))
            }, null, 8, ["disabled", "order"]))
          : (_openBlock(), _createBlock(_component_OrderTimeline, {
              key: 1,
              order: _ctx.order,
              class: "p-d-print-none"
            }, null, 8, ["order"])),
        _createVNode(_component_OrderStatusDetails, { order: _ctx.order }, null, 8, ["order"]),
        _createVNode(_component_OrderData, {
          order: _ctx.order,
          "onUpdate:order": _cache[10] || (_cache[10] = ($event: any) => (_ctx.order = $event))
        }, null, 8, ["order"]),
        _createVNode(_component_CustomerSummary, {
          id: "customer_data",
          customer: _ctx.customer,
          class: "p-mb-2"
        }, null, 8, ["customer"]),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.pickupPicker)
            ? (_openBlock(), _createBlock(_component_PickerSummary, {
                key: 0,
                picker: _ctx.pickupPicker,
                type: "ritiro",
                hideSave: true,
                class: "no_break_inside"
              }, null, 8, ["picker"]))
            : _createCommentVNode("", true),
          (_ctx.dropoffPicker)
            ? (_openBlock(), _createBlock(_component_PickerSummary, {
                key: 1,
                picker: _ctx.dropoffPicker,
                type: "consegna",
                hideSave: true
              }, null, 8, ["picker"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_OrderNotes, {
          order: _ctx.order,
          class: "p-my-2"
        }, null, 8, ["order"]),
        (_ctx.isTMT || (_ctx.isCustomer && !_ctx.isAnticipated))
          ? (_openBlock(), _createBlock(_component_PriceAndCostSummary, {
              key: 2,
              class: "p-mb-2 p-d-print-none",
              costExtraReadonly: !_ctx.isTMT,
              isReadonly: !_ctx.isTMT || _ctx.isPaid,
              order: _ctx.order
            }, null, 8, ["costExtraReadonly", "isReadonly", "order"]))
          : _createCommentVNode("", true),
        (_ctx.isCustomer && _ctx.isAnticipated && !_ctx.isRefunded)
          ? (_openBlock(), _createBlock(_component_OrderPayment, {
              key: 3,
              order: _ctx.order
            }, null, 8, ["order"]))
          : _createCommentVNode("", true),
        (_ctx.showRefundButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_Button, {
                label: "Rimborsa",
                icon: "pi pi-money-bill",
                style: {"width":"max(300px, 50%)"},
                class: "p-button-danger p-button-lg",
                onClick: _ctx.onRefundClick,
                loading: _ctx.isRefundInProgress
              }, null, 8, ["onClick", "loading"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_OrderAssignmentDialog, {
          visible: _ctx.showAssignmentDialog,
          "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => (_ctx.showAssignmentDialog = $event)),
          order: _ctx.order,
          zoneId: _ctx.order?.zone_id,
          onConfirm: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onAssignmentConfirm($event)))
        }, null, 8, ["visible", "order", "zoneId"])
      ]))
    : _createCommentVNode("", true)
}
import { mixins, Options }                      from "vue-class-component";
import { FilterMatchMode }                      from "primevue/api";
import { TableMixin }                           from "@sharedComponents";
import { OrdersRoutesEnum }                     from "../../router";
import { statusOptions }                        from "@/model/enums/OrderStatusEnum";
import { SessionStorageEnum }                   from "@/utils/SessionStorageEnum";

import OrderTable, { 
  FromTo, 
  OrdersAdvFilters 
} from "../../mixins/OrderTable";

import {
  OrderFilters,
  OrderShiftCell,
  OrderPaymentCell
} from "../../components";

import {
  AlertDialog,
  PageHeader,
} from "@/components";
import { blobToJson, fileDownload } from "@sharedUtils/utils";
import { ordersService } from "@services/orders.service";
import moment from "moment";
import { Errors } from "@/model/common/ApiResponse";

@Options({
  components: {
    AlertDialog, 
    PageHeader,
    OrderFilters,
    OrderShiftCell,
    OrderPaymentCell
  }
})
export default class OrdersAsCustomerPage extends mixins(OrderTable) {
  showAlertMessage: boolean = false;

  /**
   * Alert message generico.
   */
  alertMessage    : string  = "";

  /**
   * Status filter options
   */
  get statusOptions() {
    return statusOptions();
  }

  get newOrderRoute() {
    return {
      name: OrdersRoutesEnum.ORDERS_NEW_ZONE,
    };
  }

  get orderDetailRoute() {
    return OrdersRoutesEnum.ORDERS_DETAIL;
  }

  
  async onExport() {
    const [from, to] = this.advFilters.from_to;

    if (!from || !to) {
      this.alertMessage = "E' necessario indicare il periodo";
      this.showAlertMessage = true;
      return false;
    }

    this.export();
  }

  exportInProgress: boolean = false;
  private async export() {
    try {
      this.exportInProgress = true;

      const filters = (this.$refs.dataTable as TableMixin).filters;
      
      const response = await ordersService.exportExcel(
        false, 
        { ...filters }
      );
  
      const [startPeriod, endPeriod] = (filters.order_date.value);
      const fileName = this.buildFileName(startPeriod, endPeriod);
  
      fileDownload(
        `${fileName}.xlsx`,
        response,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      
    } catch (error: any) {
      if (error.status === 422) {
        this.showErrors(error);
      } else  {
        this.$errorMessage("Errore durante l'esportazione");
      }
    } finally {
      this.exportInProgress = false;
    }
  }

  private buildFileName(startPeriod, endPeriod) {
    return  [
      `${this.me.entity.business_name}_ordini`,
      moment(startPeriod).format("YYYYMMDD"),
      moment(endPeriod).format("YYYYMMDD"),
    ].filter(x => x).join('_')
  }

  private async showErrors(error) {
    (await blobToJson<Errors>(error.data)).errors.filters
    .forEach(errorMessage => {
        this.$errorMessage(errorMessage);
    });
  }
  
  updateFilters() {
    const table = (this.$refs.dataTable as TableMixin);

    if (table?.filters) {
      const filters = table.filters;
      
      // Period
      if(this.advFilters.from && this.advFilters.to) {
        filters.order_date.value = [
          this.advFilters.from,
          this.advFilters.to
        ];

        filters.order_date.matchMode = FilterMatchMode.BETWEEN; 
      } else if (this.advFilters.from) {
        filters.order_date.value = this.advFilters.from;
        filters.order_date.matchMode = FilterMatchMode.GREATER_THAN_OR_EQUAL_TO; 
      }

      // Zone
      filters.zone_id.value = this.advFilters?.zone?.id;

      // Payment Status & Type 
      if (this.advFilters?.paymentStatus) {
        const {
          payment_status,
          payment_type
        } = this.advFilters?.paymentStatus;

        filters.payment_status.value = payment_status;
        filters.payment_type.value = payment_type;
      }
    }

    //Session storage
    this.saveAdvancedFilters();

    // Refresh
    (this.$refs.dataTable as TableMixin)?.applyFilter();
  }

  onReset() {
    this.advFilters = new OrdersAdvFilters();
    this.saveAdvancedFilters();
  }

  saveAdvancedFilters() {
    sessionStorage.setItem(
      SessionStorageEnum.ORDERS_ADVANCED_FILTERS,
      JSON.stringify(this.advFilters)
    );
  }

  restoreAdvancedFilters() {
    this.advFilters = JSON.parse(
      sessionStorage.getItem(SessionStorageEnum.ORDERS_ADVANCED_FILTERS)
    ) || this.advFilters;

    if (this.advFilters?.from_to) {
      this.advFilters.from_to = 
        (this.advFilters?.from_to?.map(x => new Date(x))) as FromTo;
    }
  }

  private initFilter() {
    this.filters = {
      order_date: {
        value: [this.advFilters.from, this.advFilters.to],
        matchMode: FilterMatchMode.BETWEEN
      },

      external_client_code: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },

      dropoff_full_address: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },

      status: {
        value: null,
        matchMode: FilterMatchMode.IN
      },

      zone_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      payment_status: {
        value: null, 
        matchMode: FilterMatchMode.EQUALS
      },

      payment_type: {
        value: null, 
        matchMode: FilterMatchMode.EQUALS
      },
    };
  }

  created() {
    this.initFilter();
    this.restoreAdvancedFilters();
  }
}

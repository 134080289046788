import { PageHeader }                                         from "@/components";
import { EntityPickerType, PaymentType, PickerSummaryModel, } from "@/model/Entity";
import { OrderStatusEnum }                                    from "@/model/enums/OrderStatusEnum";
import { NewOrder, Order, PaymentStatusEnum }                 from "@/model/Order";
import { UserRoleEnum }                                       from "@/model/User";
import { ordersService }                                      from "@services/orders.service";
import { paymentService }                                     from "@services/payment.service";
import { zonesService }                                       from "@services/zones.service";
import { mixins, Options }                                    from "vue-class-component";
import { Prop }                                               from "vue-property-decorator";
import {
  CustomerSummary, OrderAssignmentDialog, OrderData, OrderNotes, OrderPayment, OrderStatusDetails, OrderTimeline,
  OrderToolbar, PickerSummary, PriceAndCostSummary
}                                                             from "../../components";
import {
  AssignmentPayload
}                                                             from "../../components/OrderAssignmentDialog/OrderAssignmentDialog";
import OrderActionsMixin                                      from "../../mixins/OrderActions";
import { OrdersRoutesEnum }                                   from "../../router";
import { ordersStoreModule }                                  from "../../store";

@Options( {
  components: {
    PageHeader,

    CustomerSummary,
    OrderAssignmentDialog,
    OrderData,
    OrderNotes,
    OrderPayment,
    OrderStatusDetails,
    OrderTimeline,
    OrderToolbar,
    PickerSummary,
    PriceAndCostSummary,
  }
})
export default class OrderPage extends mixins(OrderActionsMixin) {
  @Prop() readonly orderId!: string;

  order: Order = null;

  get backRoute() {
    return { name: OrdersRoutesEnum.ORDERS_LIST };
  }

  get customer() {
    return this.isCustomer
        ? this.me?.entity
        : this.order.customer;
  }

  get pickupPicker() {
    return this.order && PickerSummaryModel.fromOrder(this.order, EntityPickerType.PICKUP);
  }

  get dropoffPicker() {
    return this.order && PickerSummaryModel.fromOrder(this.order, EntityPickerType.DROPOFF)
  }

  get isPaid() {
    return this.order?.payment_status === PaymentStatusEnum.PAID;
  }

  /**
   * Ordine con pagamento anticipato
   * a prescindere dallo stato del pagamento
   */
  get isAnticipated() {
    return this.order.payment_type    === PaymentType.ANTICIPATED;
  }

  get isRefunded() {
    return this.order.payment_status === PaymentStatusEnum.REFUNDED;
  }

  get isCanceled(){
    return this.order?.status === OrderStatusEnum.ANNULLATO;
  }
  get isRejected(){
    return this.order?.status === OrderStatusEnum.RIFIUTATO;
  }

  get showSaveButton() {
    return (this.isTMT || this.isCustomer) &&
        !(this.isCanceled || this.isRejected)
  }

  /**
   * Mostra il pulsante per il rimborso se:
   * - Utente corrente è TMT ADMIN
   * - Stato ordine annullato
   * - L'ordine risulta pagato
   */
  get showRefundButton() {
    const iAmTMTAdmin = this.me?.role === UserRoleEnum.SUPER_ADMIN;
    const isCanceled = this.order?.status === OrderStatusEnum.ANNULLATO;
    return iAmTMTAdmin && isCanceled && this.isAnticipated && this.isPaid;
  }


  isCloneInProgress: boolean = false;
  isRefundInProgress: boolean = false;
  inCancelInProgress: boolean = false;

  async onAssignmentConfirm(event: AssignmentPayload, ) {
    await this.$waitFor(
      async () => {
        await this.assignOrder(event, this.order);
        this.$successMessage("Ordine aggiornato");
      }
    );

    this._loadOrder();
    this.showAssignmentDialog = false;
  }

  async onRefundClick() {
    const response = await this.$confirmMessage(
      "Sicuro di voler effettuare il rimborso?"
    );

    if (response) {
      this.refund();
    }
  }

  async refund() {
    try {
      this.isRefundInProgress = true;
      await paymentService.refund(this.order.id);
      await this._loadOrder();
      this.$successMessage("Ordine di rimborso inviato");
    } catch (error) {
      this.$errorMessage("Rimborso non riuscito");
    } finally {
      this.isRefundInProgress = false;
    }
  }

  update() {
    this.$waitFor(
      async () => {
        this.order = await ordersService.update(this.order);

        this.$successMessage("Ordine aggiornato")
      },

      "Aggiornamento ordine non riuscito"
    )
  }

  onDelete() {
    this.$confirmMessage("Sicuro di voler cancellare l'ordine")
      .then(r => {
        if (r) {
          this.$waitFor(
            async () => {
              await ordersService.remove(this.order);

              this.$router
                .replace(this.backRoute)
                .then(() => this.$successMessage("Ordine cancellato correttamente"))
            },
            "Cancellazione ordine non riuscita"
          )
        }
      })
  }

  print() {
    window.print();
  }

  async clone() {
    const zone = await zonesService.getById(this.order.zone_id);

    if (this.isCustomer) {
      this.order.customer = this.me.entity;
    }
    const clone = NewOrder.fromExistingOrder(this.order, zone);

    ordersStoreModule.mutations.setOrder(clone);

    this.$router.push({ name: OrdersRoutesEnum.ORDERS_NEW_DATA });
  }

  private _loadOrder() {
    return this.$waitFor(
      async () => {
        this.order         = await ordersService.getById( +this.orderId );
        this.selectedOrder = this.order;
      },

      "Caricamento ordine non riuscito"
    );
  }

  mounted() {
    this._loadOrder();
  }
}

import { OrderStatusEnum } from "@/model/enums/OrderStatusEnum";
import { Order } from "@/model/Order";
import { ordersService } from "@services/orders.service";
import { PrimeIcons } from "primevue/api";
import { Options, Vue } from "vue-class-component";
import { AssignmentPayload } from "../components/OrderAssignmentDialog/OrderAssignmentDialog";

@Options({})
export default class OrderActionsMixin extends Vue {

  selectedOrder: Order = null;

  showAssignmentDialog: boolean = false;

  /**
   * TODO: In attesa di chiamate "Massive"
   * Utilizzato per non visualizzare messaggi di conferma
   * al termine di ciascuna operazione. 
   * 
   * Es. Cambio stato di più ordini, mostrerebbe 1Msg x N ordini 
   */
  showMessages: boolean = true; 

  /**
   * ID della zone per il filtraggio dei Taker / Fornitori
   */
  zoneIdParam: number = null; 

  get orderStatusOptions() {
    return [
      {
        statusLabel   : "CREATO",
        value         : OrderStatusEnum.CREATO,
      },
      {
        statusLabel   : "ACCETTATO",
        actionLabel   : "ACCETTA",

        value   : OrderStatusEnum.ACCETTATO,

        command        : () => ordersService.accept(this.selectedOrder.id),
        commandWithMsg : () => this.accept(this.selectedOrder)
      },
      {
        statusLabel   : "ASSEGNATO",
        actionLabel   : "ASSEGNA",

        value   : OrderStatusEnum.ASSEGNATO,
        commandWithMsg : () => this.assign()
      },
      {
        statusLabel   : "IN ATTESA RITIRO",
        actionLabel   : "ATTENDI RITIRO",

        value   : OrderStatusEnum.ATTESA_RITIRO,
        command        : () => ordersService.waitingPickup(this.selectedOrder.id),
        commandWithMsg : () => this.waitingPickup(this.selectedOrder)
      },
      {
        statusLabel   : "RITIRATO",
        actionLabel   : "SEGNA RITIRATO",

        value   : OrderStatusEnum.RITIRATO,
        command        : () => ordersService.pickedUp(this.selectedOrder.id),
        commandWithMsg : () => this.pickedUp(this.selectedOrder)
      },
      {
        statusLabel   : "IN ATTESA CONSEGNA",
        actionLabel   : "ATTENDI CONSEGNA",

        value   : OrderStatusEnum.ATTESA_CONSEGNA,
        command        : () => ordersService.waitingDropoff(this.selectedOrder.id),
        commandWithMsg : () => this.waitingDropoff(this.selectedOrder)
      },
      {
        statusLabel   : "CONSEGNATO",
        actionLabel   : "SEGNA CONSEGNATO",

        value   : OrderStatusEnum.CONSEGNATO,
        command        : () => ordersService.droppedOff(this.selectedOrder.id),
        commandWithMsg : () => this.droppedOff(this.selectedOrder)
      },
      {
        statusLabel   : "ANNULLATO",
        actionLabel   : "ANNULLA",

        value   : OrderStatusEnum.ANNULLATO,
        command        : () => ordersService.cancel(this.selectedOrder.id),
        commandWithMsg : () => this.cancel(this.selectedOrder)
      },
      {
        statusLabel   : "RIFIUTATO",
        actionLabel   : "RIFIUTA",

        value   : OrderStatusEnum.RIFIUTATO,
        command        : () => ordersService.reject(this.selectedOrder.id),
        commandWithMsg : () => this.reject(this.selectedOrder)
      },
    ]
  }

  get orderActionsMenu() {
    return [
      {
        label: 'Stato',
        items: [
          ...this.orderStatusOptions
          .filter(x => x.value !== OrderStatusEnum.CREATO)
          .map(s => ({
            label: s.actionLabel,
            value: s.value, 
            icon: PrimeIcons.ANGLE_RIGHT,
            command: s.commandWithMsg
          }))
        ]
      },
    ]
  }


  async accept(order: Order) {
    if ( order.status === OrderStatusEnum.ACCETTATO ){
      return;
    }

    return this.$waitFor( 
      async () => {
        await ordersService.accept(order.id);

        order.status = OrderStatusEnum.ACCETTATO;
        
        this.$successMessage("Ordine accettato");
      }
    )
  }

  assign() {
    this.zoneIdParam = this.selectedOrder.zone_id;
    this.showAssignmentDialog = true
  }

  async waitingPickup(order: Order) {
    if ( order.status === OrderStatusEnum.ATTESA_RITIRO ){
      return;
    }

    return this.$waitFor( 
      async () => {
        await ordersService.waitingPickup(order.id);

        order.status = OrderStatusEnum.ATTESA_RITIRO;
        
        this.$successMessage("Ordine in attesa di ritiro");
      },
    )
  }

  async pickedUp(order: Order) {
    if ( order.status === OrderStatusEnum.RITIRATO ){
      return;
    }

    return this.$waitFor( 
      async () => {
        await ordersService.pickedUp(order.id);

        order.status = OrderStatusEnum.RITIRATO;
        
        this.$successMessage("Ordine ritirato");
      },
    )
  }

  async waitingDropoff(order: Order) {
    if ( order.status === OrderStatusEnum.ATTESA_CONSEGNA ){
      return;
    }
    
    return this.$waitFor( 
      async () => {
        await ordersService.waitingDropoff(order.id);

        order.status = OrderStatusEnum.ATTESA_CONSEGNA;
        
        this.$successMessage("Ordine in attesa di consegna");
      },
    )
  }

  async droppedOff(order: Order) {
    if ( order.status === OrderStatusEnum.CONSEGNATO ){
      return;
    }
    
    return this.$waitFor( 
      async () => {
        await ordersService.droppedOff(order.id);

        order.status = OrderStatusEnum.CONSEGNATO;
        
        this.$successMessage("Ordine consegnato");
      },
    )
  }

  async cancel(order: Order) {
    if ( order.status === OrderStatusEnum.ANNULLATO ){
      return;
    }

    const response = await this.$confirmMessage(
      "Sicuro di voler annullare l'ordine"
    );

    if (response) {
      return this.$waitFor( 
        async () => {
          await ordersService.cancel(order.id);
  
          order.status = OrderStatusEnum.ANNULLATO;
          
          this.$successMessage("Ordine annullato");
        }
      )
    }
  }

  async reject(order: Order) {
    if ( order.status === OrderStatusEnum.RIFIUTATO ){
      return;
    }
    
    return this.$waitFor( 
      async () => {
        await ordersService.reject(order.id);

        order.status = OrderStatusEnum.RIFIUTATO;
        
        this.$successMessage("Ordine rifiutato");
      },
    )
  }

  async assignOrder({entityType, supplier, taker}: AssignmentPayload, order: Order) {
    return ordersService.assignEntity(
      order.id,
      (taker||supplier)?.id || 0,
      entityType 
    );
  }
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Button, {
      label: "Export",
      icon: "fas fa-file-excel",
      class: "p-button-outlined",
      loading: _ctx.isExporting,
      onClick: _ctx.toggleMenu
    }, null, 8, ["loading", "onClick"]),
    _createVNode(_component_Menu, {
      id: "orders_status_menu",
      ref: "menu",
      model: _ctx.options,
      popup: true
    }, null, 8, ["model"])
  ], 64))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, withMemo as _withMemo, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7436eedf")
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "filters_panel" }
const _hoisted_3 = { class: "p-buttonset assignment_selector" }
const _hoisted_4 = { class: "p-d-flex" }
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "p-ml-3" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_CustomerAutocomplete = _resolveComponent("CustomerAutocomplete")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!
  const _component_SupplierAutocomplete = _resolveComponent("SupplierAutocomplete")!
  const _component_TakerAutocomplete = _resolveComponent("TakerAutocomplete")!
  const _component_ZoneAutocomplete = _resolveComponent("ZoneAutocomplete")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_OrdersExportBtn = _resolveComponent("OrdersExportBtn")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_DateFormatted = _resolveComponent("DateFormatted")!
  const _component_OrderShiftCell = _resolveComponent("OrderShiftCell")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_OrderPaymentCell = _resolveComponent("OrderPaymentCell")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AppTable = _resolveComponent("AppTable")!
  const _component_AlertDialog = _resolveComponent("AlertDialog")!
  const _component_OrderAssignmentDialog = _resolveComponent("OrderAssignmentDialog")!
  const _component_ProgressDialog = _resolveComponent("ProgressDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, {
      title: _ctx.$t('orders.list.title')
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_router_link, { to: _ctx.newOrderRoute }, {
          default: _withCtx(() => [
            _createVNode(_component_Button, {
              icon: "pi pi-plus",
              label: "Aggiungi"
            })
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Panel, {
      collapsed: false,
      class: "p-mb-3",
      header: "Filtri avanzati",
      toggleable: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Calendar, {
            modelValue: _ctx.advFilters.from_to,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.advFilters.from_to = $event)),
            manualInput: false,
            showIcon: true,
            selectionMode: "range",
            onDateSelect: _ctx.updateFilters
          }, null, 8, ["modelValue", "onDateSelect"]),
          _createVNode(_component_FloatLabel, {
            class: "p-fluid",
            label: "Cliente"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CustomerAutocomplete, {
                modelValue: _ctx.advFilters.customer,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.advFilters.customer = $event)),
                onResultSelected: _ctx.updateFilters
              }, null, 8, ["modelValue", "onResultSelected"])
            ]),
            _: 1
          }),
          _createVNode(_component_FloatLabel, {
            class: "p-fluid",
            label: "Fornitore"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SupplierAutocomplete, {
                modelValue: _ctx.advFilters.supplier,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.advFilters.supplier = $event)),
                onResultSelected: _ctx.updateFilters
              }, null, 8, ["modelValue", "onResultSelected"])
            ]),
            _: 1
          }),
          _createVNode(_component_FloatLabel, {
            class: "p-fluid",
            label: "Taker"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TakerAutocomplete, {
                modelValue: _ctx.advFilters.taker,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.advFilters.taker = $event)),
                onResultSelected: _ctx.updateFilters
              }, null, 8, ["modelValue", "onResultSelected"])
            ]),
            _: 1
          }),
          _createVNode(_component_FloatLabel, {
            class: "p-fluid",
            label: "Città"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ZoneAutocomplete, {
                modelValue: _ctx.advFilters.zone,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.advFilters.zone = $event)),
                onResultSelected: _ctx.updateFilters
              }, null, 8, ["modelValue", "onResultSelected"])
            ]),
            _: 1
          }),
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_Button, {
              class: _normalizeClass({
                'p-button-outlined': _ctx.advFilters.assignedOption !== _ctx.internal
              }),
              label: "Interni",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.assignedTo(_ctx.internal)))
            }, null, 8, ["class"]),
            _createVNode(_component_Button, {
              class: _normalizeClass({
                'p-button-outlined': _ctx.advFilters.assignedOption !== _ctx.assigned
              }),
              label: "Interni non assegnati",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.assignedTo(_ctx.assigned)))
            }, null, 8, ["class"]),
            _createVNode(_component_Button, {
              class: _normalizeClass({
                'p-button-outlined': _ctx.advFilters.assignedOption !== _ctx.external
              }),
              label: "Esterni",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.assignedTo(_ctx.external)))
            }, null, 8, ["class"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_AppTable, {
      ref: "dataTable",
      selection: _ctx.selectedOrders,
      "onUpdate:selection": _cache[12] || (_cache[12] = ($event: any) => (_ctx.selectedOrders = $event)),
      filtersSchema: _ctx.filters,
      responsiveLayout: _ctx.responsiveLayout,
      service: _ctx.service,
      showDelete: _ctx.isTMT,
      stateKey: _ctx.stateKey,
      breakpoint: "768px",
      onEdit: _cache[13] || (_cache[13] = ($event: any) => (_ctx.edit($event))),
      onPage: _ctx.resetSelections,
      onResetFilters: _ctx.onReset
    }, _createSlots({
      toolbarLeft: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cambia stato",
          disabled: !_ctx.selectedOrders?.length,
          class: "p-button-outlined",
          icon: "fas fa-tasks",
          onClick: _ctx.toggleMassiveStatusChangeMenu
        }, null, 8, ["disabled", "onClick"]),
        _createVNode(_component_Menu, {
          id: "orders_status_menu",
          ref: "osm_massively",
          model: _ctx.changeStatusOption,
          popup: true
        }, null, 8, ["model"]),
        _createVNode(_component_Button, {
          label: "Assegna",
          disabled: !_ctx.selectedOrders?.length,
          class: "p-button-outlined",
          icon: "fas fa-clipboard-list",
          onClick: _ctx.startMassiveAssignment
        }, null, 8, ["disabled", "onClick"]),
        _createVNode(_component_OrdersExportBtn, {
          advFilters: _ctx.advFilters,
          table: _ctx.$refs.dataTable,
          onError: _ctx.onExportError
        }, null, 8, ["advFilters", "table", "onError"]),
        (_ctx.selectedOrders?.length)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: "Elimina selezionati",
              class: "p-button-outlined p-button-danger p-ml-5",
              icon: "fas fa-trash",
              onClick: _ctx.onDeleteSelected
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      columns: _withCtx(({onApplyFilter}) => [
        _createVNode(_component_Column, {
          headerStyle: {width: '3em'},
          selectionMode: "multiple"
        }),
        _createVNode(_component_Column, {
          header: "ID",
          showFilterMatchModes: false,
          showFilterOperator: false,
          sortable: true,
          field: "id",
          sortField: "id"
        }, {
          body: _withCtx(({data}) => [
            _createTextVNode(" #" + _toDisplayString(data.id), 1)
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "ID ordine",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              class: "p-button-outlined",
              icon: "pi pi-times",
              type: "button",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              class: "p-button-success",
              icon: "pi pi-check",
              label: "Applica",
              type: "button",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          header: "Codice ordine",
          showFilterMatchModes: false,
          showFilterOperator: false,
          field: "external_client_code"
        }, {
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(data.external_client_code), 1)
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Codice ordine",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              class: "p-button-outlined",
              icon: "pi pi-times",
              type: "button",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              class: "p-button-success",
              icon: "pi pi-check",
              label: "Applica",
              type: "button",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1024),
        (_ctx.isTMT)
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              header: "Cliente"
            }, {
              body: _withCtx(({data}) => [
                _createVNode(_component_router_link, {
                  to: {
                name: _ctx.customerRoute,
                params: {
                  customerId: data.customer.id
                }
              },
                  class: "dark-link"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(data.customer?.business_name), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_Column, {
          header: "Indirizzo di consegna",
          showFilterMatchModes: false,
          showFilterOperator: false,
          style: {minWidth: '15rem' },
          field: "dropoff_full_address"
        }, {
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(data.dropoff_full_address), 1)
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Indirizzo di consegna",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              class: "p-button-outlined",
              icon: "pi pi-times",
              type: "button",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              class: "p-button-success",
              icon: "pi pi-check",
              label: "Applica",
              type: "button",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1024),
        (_ctx.isTMT)
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 1,
              header: "Città",
              field: "zone.name"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_Column, {
          header: "Giorno",
          style: {minWidth: '8rem' }
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("div", null, [
              _withMemo([data], () => _createVNode(_component_DateFormatted, {
                date: data.pickup_datetime
              }, null, 8, ["date"]), _cache, 8)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "Fascia oraria",
          showFilterMatchModes: false,
          sortable: true,
          style: {minWidth: '12rem', width: '12rem' },
          field: "shift_id",
          sortField: "shift_id"
        }, {
          body: _withCtx(({data}) => [
            _withMemo([data], () => _createVNode(_component_OrderShiftCell, { order: data }, null, 8, ["order"]), _cache, 9)
          ]),
          filter: _withCtx(({filterModel}) => [
            _createVNode(_component_MultiSelect, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              options: _ctx.shiftOptions,
              class: "p-column-filter",
              "option-value": "value",
              optionLabel: "label",
              placeholder: "Filtra per fascia"
            }, {
              option: _withCtx(({option}) => [
                _createElementVNode("div", null, _toDisplayString(option.label), 1)
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "options"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              class: "p-button-outlined",
              icon: "pi pi-times",
              type: "button",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              class: "p-button-success",
              icon: "pi pi-check",
              label: "Applica",
              type: "button",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, { header: "Assegnato a" }, {
          body: _withCtx(({data}) => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", null, [
                _createElementVNode("i", {
                  class: _normalizeClass(`fas fa-${data.vehicle?.icon}`),
                  title: data.vehicle?.name_it
                }, null, 10, _hoisted_5)
              ]),
              _createElementVNode("span", _hoisted_6, [
                (data.entity)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: _ctx.getEntityDetailsRoute(data.entity),
                      class: "dark-link"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(data.entity?.business_name || '--'), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(data.entity?.business_name || '--'), 1)
                    ], 64))
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "Stato",
          showApplyButton: false,
          showClearButton: false,
          showFilterMatchModes: false,
          field: "status"
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("div", {
              class: _normalizeClass(`order-badge status-${data.status}`)
            }, _toDisplayString(_ctx.$t(`order.status_${data.status}`)), 3)
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _withMemo(_ctx.statusOptions, () => _createVNode(_component_MultiSelect, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              options: _ctx.statusOptions,
              class: "p-column-filter",
              "option-value": "value",
              optionLabel: "label",
              placeholder: "Filtra per stato",
              onChange: ($event: any) => (onApplyFilter(filterCallback))
            }, {
              option: _withCtx(({option}) => [
                _createElementVNode("div", {
                  class: _normalizeClass(`order-badge status-${option.value}`)
                }, _toDisplayString(_ctx.$t(`order.status_${option.value}`)), 3)
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "options", "onChange"]), _cache, 10)
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          header: "Pagamento",
          showApplyButton: false,
          showClearButton: false,
          showFilterMatchModes: false,
          field: "payment_type"
        }, {
          body: _withCtx(({ data: {
              payment_type, payment_status, paid_at, payment_amount }
            }) => [
            _createVNode(_component_OrderPaymentCell, {
              payment_type: payment_type,
              payment_status: payment_status,
              paid_at: paid_at,
              payment_amount: payment_amount
            }, null, 8, ["payment_type", "payment_status", "paid_at", "payment_amount"])
          ]),
          filter: _withCtx(() => [
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.advFilters.paymentStatus,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.advFilters.paymentStatus = $event)),
              options: _ctx.paymentOptions,
              class: "p-column-filter",
              "option-value": "value",
              optionLabel: "label",
              placeholder: "Filtra per stato pagamento",
              onChange: _ctx.updateFilters
            }, null, 8, ["modelValue", "options", "onChange"])
          ]),
          _: 1
        })
      ]),
      _: 2
    }, [
      (_ctx.isTMT)
        ? {
            name: "actions",
            fn: _withCtx(({data}) => [
              _withDirectives(_createVNode(_component_Button, {
                "aria-controls": "overlay_menu",
                "aria-haspopup": "true",
                class: "p-button-info p-ml-1",
                icon: "pi pi-cog",
                onClick: ($event: any) => (_ctx.toggleTableActions($event, data))
              }, null, 8, ["onClick"]), [
                [
                  _directive_tooltip,
                  _ctx.$t('options'),
                  void 0,
                  { bottom: true }
                ]
              ]),
              _createVNode(_component_Menu, {
                id: "order_status_menu",
                ref: "osm",
                model: _ctx.orderActionsMenu,
                popup: true
              }, null, 8, ["model"])
            ])
          }
        : undefined
    ]), 1032, ["selection", "filtersSchema", "responsiveLayout", "service", "showDelete", "stateKey", "onPage", "onResetFilters"]),
    _createVNode(_component_AlertDialog, {
      showAlertMessage: _ctx.showAlertMessage,
      "onUpdate:showAlertMessage": _cache[14] || (_cache[14] = ($event: any) => (_ctx.showAlertMessage = $event)),
      alertMessage: _ctx.alertMessage
    }, null, 8, ["showAlertMessage", "alertMessage"]),
    _createVNode(_component_OrderAssignmentDialog, {
      visible: _ctx.showAssignmentDialog,
      "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => (_ctx.showAssignmentDialog = $event)),
      zoneId: _ctx.zoneIdParam,
      onConfirm: _cache[16] || (_cache[16] = ($event: any) => (_ctx.confirmAssignmentCallback($event)))
    }, null, 8, ["visible", "zoneId"]),
    _createVNode(_component_ProgressDialog, {
      visible: _ctx.displayProgress,
      "onUpdate:visible": _cache[17] || (_cache[17] = ($event: any) => (_ctx.displayProgress = $event))
    }, null, 8, ["visible"])
  ]))
}
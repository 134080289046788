import { Options, Vue } from "vue-class-component";
import moment from "moment";
import { OrdersRoutesEnum } from "../router";

import { Customer } from "@/model/Customer";
import { Supplier } from "@/model/Supplier";
import { Taker }    from "@/model/Taker";
import { Zone }     from "@/model/Zone";

import { AppTable } from "@sharedComponents";

import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
import { ordersService } from "@services/orders.service";
import { PaymentType } from "@/model/Entity";
import { PaymentStatusEnum } from "@/model/Order";


export enum AssignedOptionEnum {
  INTERNAL= 1,
  INTERNAL_NOT_ASSIGNED= 2,
  EXTERNAL= 3,
}

export type FromTo = [Date,Date] | Date[];

export class OrdersAdvFilters {
    customer: Customer = null;
    zone    : Zone     = null;

    /**
     * Filtro per range di date 
     * from => pickup_datetime
     * to   => dropoff_datetime
     */
    from_to?: FromTo = null;

    externalCode: string = null; 

    paymentStatus = null; 

    

    get from() {
      const from = this.from_to?.[0];
      if(from) {
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        return from;
      }

      return null; 
    }
    
    get to() {
      const to = this.from_to?.[1] ;
      if(to) {
        to.setHours(23);
        to.setMinutes(59);
        to.setSeconds(59);
        return to;
      }

      return null; 
    }

    /**
     * Per visualizzare solamente gli ordini interni (quindi per taker)
     * e poter ulteriormente filtrare solo quelli non assegnati
     * Oppure solo quelli assegnati ai fornitori esterni
     */
    assignedOption: AssignedOptionEnum = null; 

    private _supplier: Supplier = null;
    get supplier(){
      return this._supplier;
    }
    set supplier(value: Supplier) {
      if (value) {
        this._taker         = null;
        this.assignedOption = null;
      }
      this._supplier      = value;
    }

    private _taker   : Taker    = null;
    get taker(){
      return this._taker;
    }
    set taker(value: Taker) {
      if (value) {
        this._supplier      = null;
        this.assignedOption = null;
      }
      this._taker         = value;
    }


    constructor() {
      const from = moment().startOf('month');
      const to = moment().endOf('month');

      this.from_to = [ from.toDate(), to.toDate() ];
    }
}

@Options({
    components: {
      AppTable,
    },
  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith('orders')) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_ORDERS);
    }

    next();
  },
  beforeRouteLeave() {
    if (this.service?.cancelPendingRequests) {
      this.service.cancelPendingRequests();
    }
  }
})
export default class OrderActionsMixin extends Vue {

    /**
     * Filtri avanzati, esterni alla tabella 
     */
    advFilters: OrdersAdvFilters = new OrdersAdvFilters();

    /**
     * Stato di partenza dei filtri per la tabella 
     */
    filters: any = null;


    get fromDate() {
        const from = this.advFilters?.from_to?.[0];

        return from
            ? moment(from).format('DD/MM/YYYY')
            : "--/--/----";
    }

    get toDate() {
        const to = this.advFilters?.from_to?.[1];

        return to
            ? moment(to).format('DD/MM/YYYY')
            : "--/--/----";
    }

    get service() {
        return ordersService;
    }

    get stateKey() {
        return SessionStorageEnum.TABLE_STATE_ORDERS
    }

    get paymentOptions() {
      return [{ 
        label: 'Tutti', 
        value: {
          payment_type: null,
          payment_status: null
        } 
      },
        { 
          label: 'Ancitipato - da pagare', 
          value: {
            payment_type: PaymentType.ANTICIPATED,
            payment_status: PaymentStatusEnum.TO_PAY
          } 
        },
        { 
          label: 'Ancitipato - pagato', 
          value: {
            payment_type: PaymentType.ANTICIPATED,
            payment_status: PaymentStatusEnum.PAID
          } 
        },
        { 
          label: 'Ancitipato - rimborsato', 
          value: {
            payment_type: PaymentType.ANTICIPATED,
            payment_status: PaymentStatusEnum.REFUNDED
          } 
        },
        { 
          label: 'Posticipato', 
          value: {
            payment_type: PaymentType.POSTPONED,
            payment_status: null
          }
        },
      ]
    }

    openCalendar(event){
        (this.$refs as any).op?.toggle(event);
      }

    /**
     * Effettua la navigazione verso il dettaglio dell'ordine 
     * Click su pulsante edit della riga 
     */
    edit(data) {
        this.$router.push({
            name: OrdersRoutesEnum.ORDERS_DETAIL,
            params: { orderId: data.id }
        });
    }
}


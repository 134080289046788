import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { User }  from "@/model/User";
import { Order } from "@/model/Order";

import {
  UserAutocomplete
} from "@/components";
import { ordersService } from "@services/orders.service";

@Options({
  name: "OrderAssignToUser",

  components: {
    UserAutocomplete
  }
})
export default class OrderAssignToUser extends Vue {
  @Prop({ required: true })
  readonly orders!: Order[];

  get onlyOneOrder() {
    return this.orders?.length === 1;
  }

  get order() {
    return this.orders[0];
  }

  user: User = null;

  onHide() {
    this.user = null;
  }

  onShow() {
    this.user = null;
  }

  onCancel() {
    this.closeDialog();
  }

  onConfirm() {
    this.assignOrder();
  }

  isLoading: boolean = false; 
  private async assignOrder(){
    try {
      this.isLoading = true; 

      await Promise.allSettled(
        this.orders.map((order) => 
          ordersService.update({
            id: order.id,
            user_id: this.user.id
          })
        )
      )
      
      this.$successMessage(
        this.$t(
          "orders.assigned_to_user.success",
          this.orders.length
        )
      );

      this.closeDialog();

      this.$emit('update');
    } catch (error) {
      this.$errorMessage("Errore durante l'assegnazione");
    } finally {
      this.isLoading = false; 
    }
  }
  
  private closeDialog() {
    this.$emit("update:visible", false);
  }
}

  import { Prop, Options, Vue } from "vue-property-decorator";
  import { MenuItem }           from "@/primevue";
  import Menu                   from "primevue/menu";

  import { fileDownload }     from "@sharedUtils/utils";

  import { ordersService }    from "@services/orders.service";

  import { TableMixin }       from "@sharedComponents";

  @Options({
    inheritAttrs: false,
  })
  export default class OrdersExportBtn extends Vue {
    @Prop() readonly advFilters: any;

    @Prop() readonly table: TableMixin;

    get options() {
      return [
        {
          label: "Con Prezzi e Costi",
          icon: "pi pi-file-excel",
          command: () => {
            this.exportWithPricesAndCosts();
          },
        },
        {
          label: "Senza Prezzi e Costi",
          icon: "pi pi-file-excel",
          command: () => {
            this.exportWithoutPricesAndCosts();
          },
        },
      ] as MenuItem[];
    }

    toggleMenu(event: Event) {
      (this.$refs.menu as Menu).toggle(event);
    }

    private checkData() {
      const [from, to] = this.advFilters.from_to;
      
      if (!from || !to) {
        this.emitError("E' necessario indicare il periodo");
        return false;
      }

      return true;
    }

    private async exportWithPricesAndCosts() {
      if (!this.checkData()) {
        return;
      }

      const response = await this.$confirmMessage(
        `Export ordini con Prezzi e Costi. Confermi?`,
        "Conferma esportazione ordini"
      );

      if (response) {
        this.export(true);
      }
    }

    private async exportWithoutPricesAndCosts() {
      if (!this.checkData()) {
        return;
      }

      const response = await this.$confirmMessage(
        `Export ordini senza Prezzi e Costi. Confermi?`,
        "Conferma esportazione ordini"
      );

      if (response) {
        this.export(false);
      }
    }

    isExporting: boolean = false; 
    private async export(withPrices: boolean) {
      try {
        this.isExporting = true;

        const filters = this.table.filters;
        const response = await ordersService.exportExcel(withPrices, { ...filters });
        const startPeriod = (filters.order_date.value[0]);
        const endPeriod = (filters.order_date.value[1]);

        const fileName = [
          "ordini",
          startPeriod,
          endPeriod,
        ].filter(x => x).join('_');

        fileDownload(
          `${fileName}.xlsx`,
          response,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      } catch (error) {
        this.$errorMessage("Errore: export non riuscito");
      } finally {
        this.isExporting = false;
      }
    }

    private emitError(error: string) {
      this.$emit("error", error);
    }
  }
